import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CampaignIcon from "@mui/icons-material/Campaign";
import { dm } from "../../theme/enc";
import { hs, ns, bs } from "../../theme/host";

function Home() {
	SwiperCore.use([Autoplay]);
	const swiperParams = {
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
	};
	const swiperGame = {
		slidesPerView: 4,
		spaceBetween: 15,
		autoplay: false,
	};
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	const navigate = useNavigate();
	const currentDo = ns() + bs() + "5.";
	const encodedCurrentDo = currentDo + "store";
	const isAllowedDo = encodedCurrentDo === window.location[hs() + "name"];
	const [isShow, setShow] = useState(false);
	const [profile, setProfile] = useState(null);
	const [notify, setNotify] = useState();
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
			.then((res) => {
				setProfile(res.data.data);
			})
			.catch((err) => localStorage.removeItem("user"));
		axios
			.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {})
			.then((res) => {
				setNotify(res.data.data);
			});
	}, []);
	const alertFalse = () => {
		swal("Bảo trì", "Trò chơi đang bảo trì", "warning");
	};
	return (
		<>
			<div className="main">
				<div className="header header2">
					<div className="header-top">
						<div className="logo">
							<Link to="/">
								<img src={require("../../img/logo.png")} alt="Logo" />
								{/*<span
										style={{
											color: "#fff",
											fontSize: "24px",
											fontWeight: "bold",
											position: "relative",
											top: "-10px",
										}}
									>
										LOTO99
									</span>*/}
							</Link>
						</div>
						<div className="header-right">
							{profile ? (
								<div style={{ display: "flex", float: "right" }}>
									<span style={{ marginRight: "0.111rem" }}>
										Số dư:{" "}
										<b>
											<small>$</small>
											{Math.floor(profile.money).toLocaleString()}
										</b>
									</span>
								</div>
							) : (
								<div className="button-top">
									<Link to="/login" className="btn-login">
										Đăng nhập
									</Link>
									<Link to="/register" className="btn-register">
										Đăng ký
									</Link>
								</div>
							)}
						</div>
					</div>
				</div>
				{swiperParams && notify && (
					<Swiper {...swiperParams}>
						{notify.map((item, index) => (
							<>
								{index > 0 &&
								item.title !== "marquee" &&
								item.isShow === true ? (
									<SwiperSlide>
										<img
											alt={item.title.replace("}", "").replace("{", "")}
											src={item.image}
										/>
									</SwiperSlide>
								) : null}
							</>
						))}
					</Swiper>
				)}
				{notify ? (
					<>
						{notify[0].isShow == true && notify[0].title == "marquee" ? (
							<div className="marquees">
								<div>
									<svg viewBox="0 0 100 100" id="notice">
										<g>
											<path
												class="st0"
												d="M95.6,33.8c1.1-0.9,1.3-2.4,0.6-3.6L89.2,18c-0.7-1.2-2.1-1.7-3.4-1.2l-2.4,0.9l10.3,17.8L95.6,33.8z"
											></path>
											<path
												class="st0"
												d="M57,68.9c-5,1.6-10.1,3.6-14.8,6.3l-2.4,1.4l10.5,18.2c0.5,0.9,1.5,1.4,2.4,1.4c0.5,0,1-0.1,1.4-0.4l11.4-6.6   c0.6-0.4,1.1-1,1.3-1.7c0.2-0.7,0.1-1.5-0.3-2.1L57,68.9z"
											></path>
											<path
												class="st0"
												d="M14,54.1c-1.5,0.9-2.6,2.3-3.1,4c-0.2,0.7-0.3,1.5-0.2,2.2l-7.3,4.1c-0.7,0.4-1.1,1-1.3,1.7   c-0.2,0.7-0.1,1.5,0.3,2.1L9,79.8c0.5,0.9,1.5,1.4,2.4,1.4c0.5,0,0.9-0.1,1.4-0.4l7.2-4.1c1.2,0.9,2.6,1.5,4.1,1.5   c1.1,0,2.2-0.3,3.3-0.9l7.2-4.1L21.2,50L14,54.1z"
											></path>
											<path
												class="st0"
												d="M97.6,53.6l-28-48.4c-0.5-0.9-1.4-1.4-2.4-1.4c0,0,0,0,0,0c-1,0-1.9,0.5-2.4,1.4c-0.2,0.3-17.5,29.7-38.8,42   l13.4,23.2c11.8-6.8,26.3-9.9,36.3-11.2c11-1.5,19.2-1.3,19.3-1.3c1,0,2-0.5,2.5-1.4S98.1,54.5,97.6,53.6z"
											></path>
										</g>
									</svg>
								</div>
								<div>
									<div
										className="chaychu"
										style={{
											animation:
												"chuchay " +
												notify[0].content.length / 10 +
												"s linear infinite",
										}}
										dangerouslySetInnerHTML={{
											__html: notify[0].content,
										}}
									/>
								</div>
							</div>
						) : null}
					</>
				) : null}
				<div className="ic-home">
					<Link to="/recharge">
						<span className="ic-deposit"></span>
						<span className="ic-title">Nạp tiền</span>
					</Link>
					<Link to="/withdraw">
						<span className="ic-withdraw"></span>
						<span className="ic-title">Rút tiền</span>
					</Link>
				</div>
				<div className="game-content">
					<Link to="xucxac3p">
						<img src={require("../../img/dice.png")} />
						<span class="game_title">Xúc Xắc 3 phút</span>
					</Link>
					<Link to="xucxac5p">
						<img src={require("../../img/dice.png")} />
						<span class="game_title">Xúc Xắc 5 phút</span>
					</Link>
					<Link to="keno1p">
						<img src={require("../../img/keno.png")} />
						<span class="game_title">Keno 1 phút</span>
					</Link>
					<Link to="keno3p">
						<img src={require("../../img/keno.png")} />
						<span class="game_title">Keno 3 phút</span>
					</Link>
					<Link to="keno3p" className="full">
						<img src={require("../../img/kenobig.png")} />
						<span class="game_title">Keno 5 phút</span>
					</Link>
				</div>
				<Footer />
			</div>
		</>
	);
}
export default Home;
